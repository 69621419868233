import React, { useState, useEffect, useId } from 'react';

import * as Sentry from '@sentry/react';
// Import Swiper React components
// eslint-disable-next-line import/no-unresolved
import { History } from 'swiper/modules';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
import HelpPage from 'components/HelpPage';
import ErrorPanel from 'components/SadPanels/ErrorPanel';
import LoadingPanel from 'components/SadPanels/LoadingPanel';
import RotateDevicePanel from 'components/SadPanels/RotateDevicePanel';
import { Panel } from 'utils/constants/baseExperienceTypes';
import { isTouchDevice } from 'utils/helpers/mediaQueries';
import { GlobalStyles } from './styles/GlobalStyles';
import { getParsedJsonExperience } from './utils/helpers/baseExperience';
import { getComponentType } from './utils/helpers/panelParser';

const SHORT_CODE = 'shortcode';
const NO_SHORT_CODE = 'none';
declare const VITE_STAGE: string;

const isLocalDevelopment = (VITE_STAGE ?? 'local') === 'local';

Sentry.init({
  dsn: 'https://10435464bb198ac6ffd7c1e633ad1308@o4507828278984704.ingest.us.sentry.io/4507828280819712',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration({ workerUrl: '/replay-worker-min.js' })],
  environment: VITE_STAGE ?? 'local',
  enabled: !isLocalDevelopment, //uncomment if you want to trace locally
  // Tracing
  tracesSampleRate: isLocalDevelopment ? 0.0 : 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/.*tassocare\.com/],
  // Session Replay
  replaysSessionSampleRate: isLocalDevelopment ? 0.0 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: isLocalDevelopment ? 0.0 : 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default function App() {
  const [baseJsonExperience, setBaseJsonExperience] = useState<Panel[]>([]);
  const [loading, setLoading] = useState(true);
  const [phases, setPhases] = useState<string[]>([]);
  const [helpOpen, setHelpOpen] = useState(false);
  const [showRotateDeviceScreen, setShowRotateDeviceScreen] = useState<boolean>(false);
  const errorPanelId = useId();
  const [isPanelSwipable, setIsPanelSwipable] = useState<boolean>(false);

  const parseHref = (href: string): string => {
    if (href.includes('step')) {
      const matches = /\/(.{6})\/step\//.exec(href);
      if (matches) {
        return matches[1];
      }
      return NO_SHORT_CODE;
    }

    if (href.endsWith('/')) {
      href = href.substring(0, href.length - 1);
    }
    return href.substring(href.lastIndexOf('/') + 1);
  };
  const isValidShortCode = (shortCode: string): boolean => {
    return shortCode.length === 6;
  };

  localStorage.setItem(SHORT_CODE, parseHref(window.location.href));

  const handleIsPanelSwipableChange = (newState: boolean) => {
    setIsPanelSwipable(newState);
  };

  useEffect(() => {
    const shortCode = localStorage.getItem(SHORT_CODE) ?? '';
    if (isValidShortCode(shortCode)) {
      getParsedJsonExperience(shortCode, (response) => {
        setBaseJsonExperience(response.panels);
        setPhases(response.extras.phases);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }

    const handleOrientationChange = () => {
      if (!loading && isTouchDevice() && window.innerWidth > window.innerHeight) {
        setShowRotateDeviceScreen(true);
      } else {
        setShowRotateDeviceScreen(false);
      }
    };

    window.addEventListener('resize', handleOrientationChange);
    // Initial check
    handleOrientationChange();

    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, [loading]);

  const openHelpModal = () => {
    setHelpOpen(true);
  };

  if (loading) {
    return (
      <>
        <GlobalStyles />
        <HelpPage open={helpOpen} onClose={() => setHelpOpen(false)} />
        <LoadingPanel openHelpModal={openHelpModal} />
      </>
    );
  }

  if (showRotateDeviceScreen) {
    return (
      <>
        <GlobalStyles />
        <RotateDevicePanel />
      </>
    );
  }
  return (
    <>
      <GlobalStyles />
      <HelpPage open={helpOpen} onClose={() => setHelpOpen(false)} />
      <Swiper
        className="mySwiper"
        history={{
          key: `${localStorage.getItem(SHORT_CODE) ?? ''}/step`,
        }}
        pagination={true}
        modules={[History]}
      >
        {baseJsonExperience.length === 0 && (
          <SwiperSlide data-history={0} key={errorPanelId}>
            <ErrorPanel errorCode="404" openHelpModal={openHelpModal} />
          </SwiperSlide>
        )}
        {baseJsonExperience.length > 0 &&
          baseJsonExperience.map((panel, i) =>
            getComponentType({
              panel,
              order: i,
              phases,
              openHelpModal,
              handleIsPanelSwipableChange,
              isPanelSwipable,
            })
          )}
      </Swiper>
    </>
  );
}
